export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deleteIcon = require("../assets/delete.svg");
export const historyIcon = require("../assets/history.svg");
export const personIcon = require("../assets/person.svg");
export const lockIcon = require("../assets/lock.svg");
export const cardIcon = require("../assets/Vector.svg");
export const dollarIcon = require("../assets/doller.svg");
export const mailIcon = require("../assets/mail_icon.png");
export const logoutIcon = require("../assets/logout.svg");
export const visiblityOffIcon = require("../assets/visibility_off.svg");
