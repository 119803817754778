import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  fromPage:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  navigationValue:string,
  bottomTabs:{
    id:number,
    value: string,
    icon: string,
    label: string,
    path: string,
    selected_icon: string
  }[];
  drawerOpen: boolean,
  drawerMenu: {pageName:string,pagePath:string}[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BottomNavigationBarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      navigationValue:this.props.fromPage,
      bottomTabs: [
        {
          id:0,
          value: 'home',
          icon: require('../assets/home.png'),
          label: 'Home',
          path: 'HomeDashboard',
          selected_icon: require('../assets/home-selected.png')
        },
        {
          id:1,
          value: 'screens',
          icon: require('../assets/slides.png'),
          label: 'Screens',
          path: 'LocalListingAds',
          selected_icon: require('../assets/slide-selected.png')

        },
        {
          id:2,
          value: 'files',
          icon: require('../assets/files.png'),
          label: 'Files',
          path: 'LocalListingFiles',
          selected_icon: require('../assets/file-selected.png')

        },
        {
          id:3,
          value: 'settings',
          icon: require('../assets/settings.png'),
          label: 'Settings',
          path: 'Settings',
          selected_icon: require('../assets/settings-selected.png')

        } 
      ],
      drawerOpen: false,
      drawerMenu:[{pageName:'Terms & Conditions',pagePath:'Termsandconditions'},{pageName:'Privacy Policy',pagePath:'PrivacyPolicy'}]

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setActiveTabValue=(value:string)=>{
    
  }
  navigateToPage=(pageName:string,value?:string)=>{
    let navValue = value ? value : this.state.navigationValue
    this.setState({navigationValue:navValue});
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    const raiseMsg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMsg.addData("pageName",value)
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage),raiseMsg)
    this.send(msg);
  }
  handleDrawerOpen = () => {
    console.log("open")
    this.setState({drawerOpen:true});
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen:false});
  };
  navigateBack=()=>{
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
