import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  OutlinedInput,
  Box,
  Divider,
  TextareaAutosize,
  Drawer,
  Collapse,
  CircularProgress
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowBack, CheckCircle, Done, Error, PhotoCameraOutlined } from "@material-ui/icons";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '8vh',
          width: '85%',
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116': {
          width: '80% !important'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117': {
          width: '84%'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117 > div': {
          backgroundColor: 'white',
          padding: '8px 16px',

          borderRadius: '8px',
          color: '#0F172A',
          fontSize: '1em',
          boxShadow: '0px 4px 7px grey',

        }
      }
    },
    MuiDrawer: {
      root: {
        '&.upload-picture-Alert1 > .MuiPaper-root': {
          borderRadius: '16px !important',
          margin: '8px',
          marginBottom: '72px',
          backgroundColor: '#E1E1E2'
        },

      }
    },
    MuiButton: {
      root: {
        fontSize: '1em',
        textTransform: 'none',
        borderRadius: '8px',
        margin: '9px 0 9px',
        fontWeight: 700,
        '&.conatinedBtn': {
          color: 'white',
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
          padding: '12',
          marginTop: '12px',
          marginBottom: '16px'
        },
        '&.textBtn': {
          marginTop: '24px'
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 700,
        color: "#F1F5F9",
        marginBottom: "4px"
      }
    },
    MuiOutlinedInput: {
      root: {
        // border: "none",
        border: "1px solid #64748B",
        borderRadius: "8px",
        "& $notchedOutline": {
          background: "transparent",
        },
        "&:focus": {
          border: "1px solid #64748B",
        },
        "&:hover $notchedOutline": {
          border: "none", // Ensure border color remains visible on hover
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          }
        }
      },
      input: {
        background: "transparent",
        fontSize: "16px",
        color: "#94A3B8",
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: "Inter",

      }
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 700,
        color: "#F8FAFC",
        marginBottom: "4px"
      }
    },
    MuiInputBase: {
      // background: "transparent",

      input: {
        border: "none",
        background: "transparent",
        borderRadius: "8px",
        fontSize: "14px",
        color: "#94A3B8",
        fontWeight: 400,
        fontFamily: "Inter",

      },
    },
  }
});

import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController";
import { Alert } from "@material-ui/lab";
import { warningImage, headerImage } from "./assets";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const fullNameRegex = /^[a-zA-Z\s]*$/;
    const biographyRegex = /^[a-zA-Z0-9\s.,!?'"()/\-:;]*$/;
    return (
      <ThemeProvider theme={theme}>
        <Box style={{ backgroundColor: '#212121' }}>

          <Container style={{ backgroundImage: `url(${headerImage})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', padding: 0, paddingBottom: '85px', position: 'relative', width: "100%" }}>

            <Box display={'flex'}>
              <Grid item xs>
                <IconButton data-test-id="backArrowBtn" style={{padding: "12px 24px"}} onClick={() => this.navigateBack()}>
                  <ArrowBack style={webStyle.backBtn} />
                </IconButton>
              </Grid>
              <Grid item sm xs={8} style={webStyle.headerWrapper}>
                <Typography style={{ color: 'white', textTransform: 'uppercase', fontSize: '14px', fontWeight: 700, textAlign: 'center', lineHeight: '22px' }}>{'EDIT PROFILE'}</Typography>

              </Grid>
              <Grid item xs container style={webStyle.doneWrapper}>
                <IconButton data-test-id="updateProfileBtn" style={{ padding: '8px 24px' }} onClick={() => this.updateProfile()}>
                  <Done style={webStyle.doneIcon} />
                </IconButton>
              </Grid>
            </Box>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'absolute', top: '65px' }}>
              <IconButton data-test-id="selectImageBtn" onClick={() => this.openPopUp()} style={{ padding: '0px' }}>
                {/* backgroundImage:`url(${this.state.profilePicture})` */}
                <div style={{ width: '130px', height: '130px', backgroundColor: '#F1F5F9', backgroundImage: this.state.profilePicture ? `url(${this.state.profilePicture})` : '', backgroundSize: 'cover', borderRadius: '50%', border: '4px solid', borderColor: `${this.state.showChangeProfilePopUp ? "#F0E5FF" : "#FFF"}`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: "relative", opacity: 0.6 }}>
                </div>
              </IconButton>
              <div style={{position: "absolute", display: 'flex', flexDirection: 'column', top: '47px', justifyContent: 'center', alignItems: 'center'}} onClick={() => this.openPopUp()} >
                  <PhotoCameraOutlined style={{ fontSize: '24px', color: this.state.profilePicture ? 'white' : 'black', marginBottom: '5px' }} />
                  <Typography style={{ fontSize: '14px', fontWeight: 700, color: this.state.profilePicture ? 'white' : 'black' }}>{this.state.profilePicture ? 'Edit picture' : 'Add picture'}</Typography>

              </div>
            </div>
          </Container>
          {this.state.loading && (
            <Box sx={webStyle.loaderWrapper} position={"fixed"}>
              <CircularProgress />
            </Box>
          )}
          <Container maxWidth='md' style={{ padding: '0 24px', marginTop: '85px' }}>
            <form style={webStyle.form}>
              <InputLabel style={webStyle.inputFielTitle}>
                Full name
              </InputLabel>
              <OutlinedInput
                fullWidth
                name="fullName"
                value={this.state.fullName}
                type={'text'}
                id="fullName"
                style={webStyle.inputFieldValue}
                data-test-id='fullNameTestId'

                onChange={(event) => this.editUserProfile('fullname', event.target.value)}
              />

              {(this.state.saveEnabled && !this.state.fullName) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Full Name is required</span>}
              {!fullNameRegex.test(this.state.fullName) && (
                  <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>
                    Only alphabetic characters are allowed
                  </span>
                )}
              <InputLabel style={webStyle.inputFielTitle}>
                Email
              </InputLabel>
              <OutlinedInput
                fullWidth
                name="emailAddress"
                value={this.state.email}
                type={'text'}
                id="emailAddress"
                style={webStyle.inputFieldValue}
                data-test-id='emailAddress'
                onChange={(event) => {
                  this.validateEmail()
                  this.editUserProfile('email', event.target.value)
                }}
              />
              {(this.state.saveEnabled && !this.state.email) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Email is required</span>}
              {!emailRegex.test(this.state.email) && (
                <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>
                  Please enter a valid email address
                </span>
              )}

              <InputLabel style={webStyle.inputFielTitle}>
                Location
              </InputLabel>
              <OutlinedInput
                fullWidth
                inputProps={{
                  maxLength: 30
                }}
                name="location"
                value={this.state.city}
                type={'text'}
                id="location"
                style={webStyle.inputFieldValue}
                data-test-id='locationInput'
                onChange={(event) => this.editUserProfile('location', event.target.value)}

              />
               {!fullNameRegex.test(this.state.city) && this.state.city !== '' && (
                  <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>
                    Only alphabetic characters are allowed
                  </span>
                )}
              <InputLabel style={webStyle.inputFielTitle}>
                Bio
              </InputLabel>
              <TextareaAutosize
                value={this.state.biography}
                id="bio"
                placeholder="Short bio about yourself"
                style={{ ...webStyle.inputFieldValue, maxWidth: '100%', width: '100%', padding: '18.5px 14px', fontSize: "16px" }}
                data-test-id='bioTextArea'
                minRows={5}
                onChange={(event) => this.editUserProfile('bio', event.target.value)}
              />
              {!biographyRegex.test(this.state.biography) && this.state.biography !== '' && (
                <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>
                  Only alphabetic and numeric characters are allowed
                </span>
              )}
              {this.state.biography.length > 150 && (
                <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>
                  Maximum 150 characters are allowed
                </span>
              )}
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', marginTop: '28px', paddingBottom: '10px', borderBottom: '1px solid #64748B', width: '100%' }}>
                <Typography style={{ textTransform: 'uppercase', color: '#F1F5F9', fontSize: '12px', fontWeight: 700, }}>Social Links</Typography>
              </Box>
              <InputLabel style={webStyle.inputFielTitle}>
                Facebook
              </InputLabel>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  width: '100%' }}>
                <OutlinedInput
                  fullWidth
                  name="facebookURL"
                  value={this.state.facebook}
                  type={'text'}
                  id="facebookURL"
                  style={webStyle.inputFieldValue}
                  data-test-id='facebookURLInput'
                  onChange={(event) => this.editUserProfile('facebook', event.target.value)}
                />
              </Box>
              <InputLabel style={webStyle.inputFielTitle}>
                LinkedIn
              </InputLabel>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px', width: '100%' }}>
                <OutlinedInput
                  fullWidth
                  name="linkedInInput"
                  value={this.state.linkedIn}
                  type={'text'}
                  id="linkedInInput"
                  style={webStyle.inputFieldValue}
                  data-test-id='linkedInInput'
                  onChange={(event) => this.editUserProfile('linkedin', event.target.value)}
                />
              </Box>
              <Collapse className="alertCollapse" in={this.state.showErrMsg} >
                <Alert icon={this.state.errorMsg.type == 'error' ? <Error style={{ color: 'red' }} fontSize="inherit" /> : <CheckCircle style={{ color: '#34D399' }} fontSize="inherit" />} severity={this.state.errorMsg.type == 'error' ? "error" : 'success'} style={{ width: '100%' }}>
                  {this.state.errorMsg.msg}
                </Alert>
              </Collapse>
            </form>
            <Drawer data-test-id="upload-picture-alert1" className="upload-picture-Alert1" anchor={'bottom'} open={this.state.showChangeProfilePopUp}  >
              <div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 24px 0px' }}>

                  <Typography
                    style={{ fontSize: '0.84em', fontWeight: 400, color: '#3C3C43', textAlign: 'center', padding: '8px' }}
                  >
                    Edit profile picture
                  </Typography>
                  <Divider />
                  <input
                    data-test-id="click-photo"
                    ref={this.takePhotoRef}
                    name="takePhoto"
                    style={{ visibility: 'collapse', height: '2px' }}
                    type="file"
                    accept="image/*"
                    capture='environment'
                    onChange={(event) => this.clickImage(event.target, this.takePhotoRef)}
                  />

                  <label>
                    <input
                      data-test-id="take-photo"
                      style={{ visibility: 'collapse', height: '2px' }}
                      type="file"
                      accept="image/*"
                      capture="environment"
                      ref={this.handleFileInput}
                      onChange={(event) => this.handleImageChange(event)}
                    />
                  </label>

                  <Button
                    data-test-id={`clickBtn`}
                    variant="text"
                    onClick={() => this.handleClick()}
                  >
                    {'Take photo'}
                  </Button>

                  <Divider />
                  <input
                    data-test-id="image-upload"
                    ref={this.uploadProfileRef}
                    name="uploadProfile"
                    style={{ visibility: 'collapse', height: '2px' }}
                    type="file"
                    accept="image/*"
                    onChange={(event) => this.getImage(event, this.uploadProfileRef)}
                  />
                  <Button
                    data-test-id={`uploadBtn`}
                    variant="text"
                    onClick={() => this.upsertUploadProfile(this.uploadProfileRef)}
                  >
                    {'Upload photo'}
                  </Button>
                  <Divider />
                  <Button
                    data-test-id={`removeBtn`}
                    variant="text"
                    onClick={() => this.removeImage()}
                  >
                    Remove photo
                  </Button>
                  <Divider />

                </div>
                <div className="MuiPaper-root MuiDrawer-paper MuiDrawer-paperAnchorBottom MuiPaper-elevation16" style={{ margin: '8px', borderRadius: '16px' }}>
                  <Button
                    variant="text"
                    onClick={() => this.closePopUp()}
                    data-test-id='cancelBtn'

                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Drawer>
            {this.state.imgValidation.show &&
              <Box sx={webStyle.successStatusWrapper} data-test-id="image-type-validation">
                <Box sx={webStyle.successStatus}>
                <img src={warningImage} style={webStyle.imgStyle}/> {this.state.imgValidation.type} format not supported 
                </Box>
              </Box>
            }
          </Container>
        </Box>

      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    paddingBottom: "30px",
    marginTop: '20%',
    height: '80%',
    alignItems: 'center'
  },
  backBtn: { 
    height: '24px', 
    width: '24px', 
    color: 'white' 
  },
  headerWrapper: { 
    display: 'flex', 
    alignItems: 'center' ,
    justifyContent: 'center'
  },
  doneWrapper: { 
    justifyItems: 'flex-end', 
    display: 'grid' 
  },
  doneIcon: { 
    height: '24px', 
    width: '24px', 
    color: 'white' 
  },
  errorText: { 
    fontSize: '0.75em', 
    color: '#DC2626', 
    marginBottom: '24px' 
  },

  form: {
    marginBottom: 0,
    width: '100%', // Fix IE  11  issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 5),

    color: "white",
  },
  alertBox: {
    backgroundColor: '#FEE2E2',
    padding: '8px 12px',
    borderLeft: '4px solid red',
    borderRadius: '4px',
    fontSize: '0.7em',
    margin: '12px 0',
    width: '90%',
    color: 'red',
  },
  inputFielTitle: {
    fontSize: '14px',
    fontWeight: '700',
    margin: '0 0 4px',
    color: '#F1F5F9',
    lineHeight: '22px'
  },
  inputFieldValue: {
    margin: '0px 0px 5px',
    color: '#94A3B8',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontFamily: 'Inter',
    border: '1px solid #64748B',
    marginBottom: '15px',
    '&.MuiInputBase-root':{
      margin: 0
    }
  },
  uploadBtn: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 500,
    fontSize: '14px',
    background: '#d5d5d5',
    borderRadius: '5px'
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '15px'
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    top: 0,
    zIndex: 9999,
    width: '100%',
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    position: 'fixed',
    // top: '57vh',
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#FEE2E2",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
    bottom: 40
  },
  successStatusWrapper: {
    // position: "absolute",
    bottom: "50px",
    width: "100%",
    display: "flex",
    justifyContent: 'center',
  },
  imgStyle: {
    margin: "10px 8px 10px 0"
  }
};
// Customizable Area End
