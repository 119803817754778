import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  // Customizable Area Start
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      color: 'green',
      fontWeight: 'bold',

    },
    h5: {
      fontWeight: 600,
      color: "red"
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body2: {
      fontSize: '0.7em',
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {

        height: "16px",
        width: "16px",
      },
    },
    MuiInputBase: {
      root: {
        color:'#E2E8F0',
        borderRadius: '8px !important',
        height: '3.6em'
      },

      input: {
        padding: '10.5px',

        "&:focus": {

          borderRadius: '2px',
        },
        "&::placeholder": {
          fontSize: "16px",
        },
      },
    },
   
    MuiButton: {
      root: {
        textTransform: 'none',
        background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        height: '3.6em',
        borderRadius: '8px',
        fontSize: '1em',
        fontWeight: 700
      }
    }
  },
});
import { app_logo,imgviewa, imgviewb } from "../../email-account-login/src/assets";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
         <div style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',width:'100vw',position:'fixed',backgroundSize: '100% 100%',backgroundRepeat: 'no-repeat'}}>
        <Container maxWidth={"sm"} style={{overflow:'scroll',height:'100vh',}}>
        <img src={imgviewa} alt="imgviewa" style={{position:'absolute',top:"0px",right:"-25px"}}></img>
          <img src={imgviewb} alt="imgviewb" style={{position:'absolute',bottom:0,left:0}}></img>
          <Box sx={webStyle.mainWrapper}>
            <img src={app_logo} alt="app_logo" style={webStyle.logos}/>

            <Container style={{ padding: '0', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100%' }}>
              {this.state.errorMsg &&
                <Box sx={webStyle.alertBox} className="alert-box">
                  {this.state.errorMsg}
                </Box>
              }
              <Box sx={{ width: "100%" }}>
                <Box sx={{ fontSize: '1.1em', color: '#FFFFFF', fontWeight: '700', margin: '16 0 8', }}>Forgot password?</Box>
                <Box sx={{  color: '#FFFFFF',fontSize: '1em', margin: '0 0 8' ,fontWeight: '400',}}>No worries, we'll send a recovery link to your email.</Box>
                <form autoComplete="off" autoSave="off" style={webStyle.form}>
                  <InputLabel style={{ fontSize: '1em', fontWeight: '700', margin: '24 0 8',color:'#E2E8F0'}}>
                    Email
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    placeholder="Your email"
                    name="email"
                    autoComplete="off"
                    autoCorrect="off"
                    autoSave="off"
                    data-test-id="emailinput"
                    value={this.state.emailValue}
                    onChange={(event) => {
                      this.setEmailValue(event.target.value)
                      if (event.target.value != '') {
                        this.setState({errorMsg:"", isMailRequired: false})
                      }
                    } 
                  }
                    style={{ backgroundColor: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',marginBottom: '5px', color: '#E2E8F0', border: `1px solid ${!this.state.isMailRequired ? '#CBD5E1' : '#F87171'}`,borderRadius:'8px'}}
                    error={this.state.isMailRequired}
                  />


                  <Button
                    data-test-id="submitBtn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={webStyle.submit}
                    onClick={this.sendRecoveryLink}
                  >
                    Send a recovery link          </Button>
                  <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'baseline' }}>
                    <span style={{ color: '#E2E8F0',fontSize: '1em'}}>Back to</span>
                    <Button data-test-id="linkToNavigate" onClick={this.naviagteToLogIn} variant="text" style={{ color: '#F8FAFC',marginLeft: 3,fontWeight: 700,background:'none'}}>
                      Log in
                    </Button>
                  </div>
                </form>
              </Box>
            </Container>
          </Box>
        </Container>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  logos: {
    marginTop: "80px",
    filter: 'invert(1)',
  },
  alertBox: {
    backgroundColor: '#FEE2E2',

    color: 'red',
    borderLeft: '4px solid red',
    borderRadius: '4px',

    padding: '8px 12px',
    margin: '12px 0',
    fontSize: '0.9em',
    width: '90%',

  },
  mainWrapper: {
    fontFamily: "Inter !important",
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",

    height: '80%'
  },





  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    color:'#1E293B',
    background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
  },
};
// Customizable Area End
