import React from 'react';

import { 
    styled,
    Box,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    IconButton,
    Button
} from '@material-ui/core';
import { FaArrowLeft } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { get } from '../commonTable/table';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';
export const configJSON = require("../config");

const styles = {
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
  buttonArea: {
    bottom: '18px',
    width: '100%',
    maxWidth: 'calc(100% - 40px)',
  },
};


export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

class ManagerUserDetail extends BlockComponent<Props, any, SS> {
  getUserDataApiID: string = '';

    constructor(props: any) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            modalAdd: false,
            fileDetailData: {},
            viewAd: false,
            errorMessage: '',
            screenUrlCopy: false,
        };
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getUserDetails();
    }

    apiCall = async ({
        method, endPoint, contentType, body, isFormData
    }: any) => {
        const token = await getStorageData("token");
        const header = {
            "Content-Type": contentType,
            token,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        body && requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            isFormData ? body : JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage;
    }

	getUserDetails = async () => {
    const userId = this.props.navigation.getParam('userId');

		const getUserDetailsAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.ViewUserDetailsApiEndPoint}${userId}`, 
			contentType: "application/json",
		})

		this.getUserDataApiID = getUserDetailsAPI.messageId;
	}

    getFieldValue = (keyValue: string): string => {
      const data = get(this.state.fileDetailData, `attributes.${keyValue}`)
        return data == undefined ? '-' : data
    }

    goBack = () => {
        this.props.navigation.goBack()
    }

    navigateToPage = (path: string, params: any) => {
      this.props.navigation && this.props.navigation.navigate(path, params)
  }

    goToScreen = () => {
      const userId = this.props.navigation.getParam('userId');
      this.navigateToPage(`AdList`, {userId: userId})
    }

    async receive(from: string, message: Message) {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId == this.getUserDataApiID) {
            if (!responseJson.errors && responseJson.data) this.setState({ fileDetailData: responseJson.data });
            else { this.setState({ fileDetailData: {} }) }
        }
    }

  render() {

    return (
      <>
      <div style={{height: "100vh", overflowY: "hidden"}}>
        <StyledHeader>
          <FaArrowLeft style={{color: '#FFF'}} data-test-id="go-back" onClick={this.goBack} />
          <h1 style={styles.headerTitle}>User Details</h1>
        </StyledHeader>

        <DetailedScreenBox>
          <List className='list-items'>
            <ListItem>
              <ListItemText 
                id="full_name"
                primary={"User Name"} 
                secondary={this.getFieldValue("full_name")} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText
                key="email" 
                primary={"Email ID"} 
                secondary={this.getFieldValue("email")} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"Date Joined"} 
                id="date_joined"
                secondary={`${this.getFieldValue("date_joined")}`} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"Number of Ads Purchased"} 
                secondary={`${this.getFieldValue("total_ads_purchased")}`} 
                id="total_ads_purchased"
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                id="total_amount_paid"
                primary={"Total Amount Paid"} 
                secondary={this.getFieldValue('total_amount_paid')} 
              />
            </ListItem>
          </List>
          <Box
            sx={{ position: 'fixed', 
              boxSizing: 'border-box',
            }} 
            style={styles.buttonArea}
            >
          <ButtonWrapper>
          <GoBackToScreenButton onClick={this.goToScreen}>
            View Files
          </GoBackToScreenButton>
          </ButtonWrapper>
          </Box>
        </DetailedScreenBox>
        </div>
      </>
    );
  }
}

export default ManagerUserDetail;

const DetailedScreenBox = styled(Box)({
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  padding: "16px",
  paddingTop: "8px",
  paddingBottom: "200px",
  height: 'calc(100% - 200px)',
  overflowY: "auto",

  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const StyledHeader = styled(Box)({
  position: 'sticky',
  top: 0,
  boxShadow: "none",
  zIndex: 999,
  padding: "20px 16px",
  paddingBottom: '0px',
  color: "#FFF",
  borderBottom: "1px solid #64748B",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'
});

const GoBackToScreenButton = styled(Button)({
  padding: '16px 0px 16px 0px',
  gap: '8px',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  fontWeight: 'bold',
  fontSize: '16px',
  width: '90%',
  margin:"auto",
  textTransform: 'none',
})


const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0 10px 0',
  width: '100%',
});