import React from "react";
import LocalListingAdsController, { Props } from "./LocalListingAdsController";
import {
  FaArrowLeft,
  FaRegHeart,
  FaMinusCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { girl } from "./assets";
import { IconContext } from "react-icons/lib";
// Customizable Area Start
import {AppBar, Toolbar, Box, Button, Typography, styled, InputAdornment, TextField, OutlinedInput, Popover, Divider, Drawer, TableContainer, List, ListItem, ListItemText, Grid} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import { FaSearch } from "react-icons/fa";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ScreenTable from '../../../components/src/commonTable/ScreenList';
import { MdEventAvailable, MdOutlineFileUpload, MdOutlineDvr , MdClear } from "react-icons/md";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Alert } from "@material-ui/lab";
import moment from 'moment';
import { success_icon, search_24px } from './assets';
import { secondsToTime } from '../../../components/src/addNewScreen';

const configJSON = require("./config");

const moreMenu = [
  {
    title: "Upload",
    key: 'upload',
    icon: <MdOutlineFileUpload />
  },
  {
    title: "Check availability",
    key: 'check_availability',
    icon: <MdEventAvailable />
  },
  {
    title: "Details",
    key: 'details',
    icon: <MdOutlineDvr />
  }
]

type StringOrNumber = string | number | null;
type recentScreenListType =  {[key:string]: string}
// Customizable Area End

export default class LocalListingAds extends LocalListingAdsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderHeader = () => {
    return (
      <StyledHeader>
        <FaArrowLeft data-test-id="go-back" onClick={this.handleBack} />
        {!this.state.isSearch && (
          <Typography className="pageTitle">
            {configJSON.screenHeader}
          </Typography>
        )}
        {this.state.isSearch ? (
          <Typography data-test-id="search_data" className="pageTitle" onClick={this.handleSearchScreenList}>
            {configJSON.headerSearchTxt}
          </Typography>
        ) : (
          <div data-test-id="searchIcon" onClick={this.handleSearch}>
            <img
              src={search_24px}
              alt="upload media"
              style={{ width: "17px", height: "17px",}}
            />
          </div>
          // <FaSearch data-test-id="searchIcon" onClick={this.handleSearch} />
        )}
      </StyledHeader>
    );
  };

  renderTopActions = () => {
    return (
      <>
        <TopActions>
          {this.state.isSearch && (
            <StyledTextField
              data-test-id="search_screens"
              variant="outlined"
              value={this.state.screenSearch}
              placeholder={configJSON.searchScreensPlaceholderTxt}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.screenSearch && (
                      <MdClear data-test-id="clear_input" className="clearIcon" onClick={this.clearSearch} />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={this.handleSearchScreen}
            />
          )}

          <MenuClick data-test-id="region-menu" onClick={this.handleMenuOpen}>
            <Typography className="menuSelectTxt">{ this.state.selectedRegion || configJSON.selectRegionTxt}</Typography>
            <KeyboardArrowDownIcon />
          </MenuClick>
          {this.state.isSearch && (
            <RightAlignedTypography data-test-id="clear_data" className="pageTitle" onClick={this.clearSearchAll}>
            {"Clear"}
            </RightAlignedTypography>  
           )
          }
          <StyledPopover
            data-test-id="popover-data"
            elevation={3}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            <Box className="formControl">
              <StyledTextField
                variant="outlined"
                placeholder={configJSON.headerSearchTxt}
                value={this.state.regionSearch}
                onChange={this.handleRegionSearch}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
                }}
                />
            </Box>
            <ul className="regionsList">
              {
                this.state.regionsList.filter((region) => region.name.toLocaleLowerCase().includes(this.state.regionSearch.toLocaleLowerCase())).map((region) => {
                  return (
                    <li data-test-id="region_item" key={region.id} className="regionsListItem" onClick={() => {
                      this.handleMenuClose();
                      this.selectRegion(region.name)
                    }}>
                      {region.name}
                    </li>
                  )
                })
              }
            </ul>
          </StyledPopover>
        </TopActions>
      </>
    );
  };

  renderRecentSearch = () => {
    return (
      <>
        <RecentSearch>
          <Typography className="rsTitle">{configJSON.recentSearchTxt}</Typography>
          <div className="recentSearch">
          {
            this.state.recentScreenList.map((screen: recentScreenListType, index: number)=> {
              return(
                <ScreenListItem key={`${screen.region_name}-${index}`} data-test-id={`${screen.region_name}-${index}`} onClick={() => this.handleRecentSearch(screen)}>
                  <Typography className="screenName" >{screen.screen_name}</Typography>
                  <Typography className="region">{screen.region_name}</Typography>
                  <ChevronRightIcon className="icon"/>
                </ScreenListItem>
              )
            })
          }
        </div>
        </RecentSearch>
      </>
    );
  };

  renderScreens = () => {
    return (
      <>
        <RenderScreens>
          <Typography className="rsTitle">{this.state.screenList.length} {configJSON.regionScreensResultTxt}</Typography>
          <CustomDivider variant="middle" />
          <TableBox>
            <UpdatedTableContainer >
              <ScreenTable 
                data-test-id="table"
                headerKeys={configJSON.screenListHeaderKeys}
                tableData={this.state.screenList}
                showFilter={true}
                moreMenuList={moreMenu}
                isLoading={this.state.screenLoader}
                handleMoreItem={this.handleScreenMoreMenuClick}
                handleHeaderFilter={this.handleHeaderFilter}
                navigation={this.props.navigation}
              />
            </UpdatedTableContainer>
          </TableBox>
        </RenderScreens>
      </>
    );
  };

  renderDetailScreens = () => {
    return (
      <Drawer anchor="bottom" open={this.state.screenDetail} onClose={() => this.setState({ screenDetail: false })} >
        <DetailedScreenBox>
          <DetailedScreenTitle>
            <Typography className="detailTitle">{this.getFieldValue("screen_name")}</Typography>
            <Typography variant="body1" className="detailRegion">{this.getFieldValue("region")}</Typography>
          </DetailedScreenTitle>
          <CustomDivider />
          <DetailedScreenDescription>
            {this.state.selectedButton === 'details' ? (
              <List className='list-items'>
                <ListItem>
                  <ListItemText 
                    id="Media_format"
                    primary={"Media Format"} 
                    secondary={this.getFieldValue("media_format")} 
                  />
                </ListItem>
                {this.getFieldValue('status') === 'active' && (
                  <ListItem>
                    <ListItemText
                      key="FileName" 
                      primary={"File Name"} 
                      secondary={this.getFieldValue("files.file_name")} 
                    />
                  </ListItem>
                )}
                {this.getFieldValue('status') === 'active' && (
                  <ListItem>
                    <ListItemText
                      key="FileName" 
                      primary={"Duration"} 
                      secondary={secondsToTime(this.getFieldValue('duration'))} 
                    />
                  </ListItem>
                )}
                {this.getFieldValue('status') === 'active' && (
                  <ListItem>
                    <ListItemText 
                      primary={"Start Date"} 
                      id="start_date"
                      secondary={this.getFieldValue("files.start_date")} 
                    />
                  </ListItem>
                )}
                {this.getFieldValue('status') === 'active' && (
                  <ListItem>
                    <ListItemText 
                      primary={"End Date"} 
                      key="end_date"
                      secondary={this.getFieldValue("files.end_date")} 
                    />
                  </ListItem>
                )}
              </List>
            ) : (
              <Grid container spacing={2} justifyContent="center" alignItems="center" style={{width: "100%", margin: 0}}>
                <Grid item xs={12}>
                  <CustomAlert severity="error" icon={false} style={this.getChipColor(styles)} data-test-id="screen-chip-color" >
                    <div>Screen is {this.getFieldValue('status')}</div>
                  </CustomAlert>
                </Grid>
                <Grid item xs={12}>
                  <Label>Status</Label>
                  <Autocomplete
                    id="combo-box"
                    data-test-id="status"
                    fullWidth
                    options={configJSON.ScreenStatusData as string[]}
                    disabled
                    value={this.getFieldValue('status')}
                    renderInput={(params) => <StyledTextFieldAutocomplete {...params} style={{color: 'white'}} variant="outlined" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Label>Media Format</Label>
                  <Autocomplete
                    id="combo-box"
                    data-test-id="media_status"
                    fullWidth
                    options={configJSON.ScreenFileTypeData as string[]}
                    disabled
                    value={this.getFieldValue('media_format')}
                    renderInput={(params) => <StyledTextFieldAutocomplete {...params} style={{color: 'white'}} variant="outlined" />}
                  />
                </Grid>
                {this.getFieldValue('status') === 'active' && (
                  <>
                  <Grid item xs={6} style={{paddingRight:"8px"}}>
                    <Label>Start Date</Label>
                    <CustomOutlinedInput
                      id="start_date"
                      type="date"
                      data-test-id="start_date"
                      fullWidth
                      inputComponent={() => 
                        <Typography style={{padding:"15px 0px",color:"#FFF"}}>{moment(this.getDateValue('start_date'),'DD/MM/YYYY').format('DD.MM.YYYY')}</Typography>
                      }
                      value={moment(this.getDateValue('start_date'),'DD/MM/YYYY').format('DD.MM.YYYY')}
                      startAdornment={<InputAdornment position="start"><CalendarTodayIcon className="white-icon"/></InputAdornment>}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} style={{paddingLeft:"8px"}}>
                    <Label>End Date</Label>
                    <CustomOutlinedInput
                      id="end_date"
                      data-test-id="end_date"
                      fullWidth
                      inputComponent={() => 
                        <Typography style={{padding:"15px 0px",color:"#FFF"}}> {moment(this.getDateValue('end_date'),'DD/MM/YYYY').format('DD.MM.YYYY')}</Typography>
                      }
                      type="date"
                      value={moment(this.getDateValue('end_date'),'DD/MM/YYYY').format('DD.MM.YYYY')}
                      startAdornment={<InputAdornment position="start"><CalendarTodayIcon className="white-icon"/></InputAdornment>}
                      disabled
                    />
                  </Grid>
                  </>
                )}
              </Grid>
          )}
          </DetailedScreenDescription>
          <DetailedScreenActions>
            <GoBackToScreenButton data-test-id="close_drawer" onClick={() => this.handleScreenDetailDrawerClose()}>
              {(this.state.selectedButton === 'check_availability' && this.getFieldValue('status') !== 'unavailable') ? 
                configJSON.upload :
                configJSON.back 
              }
            </GoBackToScreenButton>
          </DetailedScreenActions>
        </DetailedScreenBox>
      </Drawer>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <HamburgerMenu 
        navigation={this.props.navigation} 
        fromPage="screens" 
        hideBottom={!!this.state.isSearch}
      >
        <Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
          <Toolbar>
            {this.renderHeader()}
          </Toolbar>
          <MainContent>
            {this.renderTopActions()}
            {this.state.isSearch && this.renderRecentSearch()}
            {!this.state.isSearch && this.renderScreens()}
            {this.renderDetailScreens()}
          </MainContent>
        </Box>
        {this.state.displayAlert ? <Box style={{...styles.successStatusWrapper, position: 'sticky'}} data-test-id="card_success">
          <Box style={styles.successStatus}>
            <img src={success_icon} style={{height: '25px', width: '25px'}}/> {this.state.displayAlertMessage}
          </Box>
        </Box> : null}
      </HamburgerMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
 availableAlert: {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  borderLeft: '3px solid #D97706',
 },
 unavailableAlert: {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fdecea',
  color: '#f44336',
  borderLeft: '3px solid #f44336',
 },
 activeAlert: {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#D1FAE5',
  color: '#059669',
  borderLeft: '3px solid #059669',
 },
 successStatusWrapper: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    margin: 'auto',
    bottom: 20,
    zIndex: 9999,
  },
  successStatus: {
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    // top: '57vh',
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#FFF",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
    bottom: 40
  },
};
const StyledTextFieldAutocomplete = styled(TextField)({
  '& .MuiInputBase-input': {
    textTransform: 'capitalize',
  },
})
const StyledHeader = styled(AppBar)({
  width: '100%',
  display: "flex",
  flexDirection: 'row',
  justifyContent: "space-between",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  boxShadow: "none",
  alignItems: "centner",
  padding: "20px 16px 16px 20px",
  color: "#FFF",
  "& .pageTitle":{
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  borderBottom: "1px solid #64748B",
});

const MainContent = styled(Box)({
  background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
  minHeight: "80vh",
});

const UpdatedTableContainer = styled(TableContainer)({
  flexGrow: 1,
  overflow: 'auto',
})

const TopActions = styled(Box)({
  padding: "16px 16px 30px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "12px"
})

const CustomOutlinedInput = styled(OutlinedInput)({
  '& .MuiOutlinedInput-input':{
    padding:"18.5px 0px"
  },
  '@media (max-device-width:330px)': {
    '&.MuiOutlinedInput-adornedStart': {
      paddingLeft: '6px !important',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: '2px',
    },
  },
});

const StyledTextField = styled(TextField)({
  background: "#01010199",
  borderRadius: "8px",
  width: "100%",
  
  "& .MuiOutlinedInput-root":{
    height: "48px",
  },
  "& .MuiOutlinedInput-input":{
    padding: "0",
    color: "#94A3B8",
  },
  "&.MuiOutlinedInput-notchedOutline":{
    border: "none"
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "transparent"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
    borderWidth: "0px"
  },
  "& .MuiOutlinedInput-input::placeholder":{
    color: "#94A3B8",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px"
  },
  "& .searchIcon":{
    color: "#94A3B8"
  },
  "& .clearIcon":{
    color: "#94A3B8",
    cursor: "pointer"
  },
  "& MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart": {
    border: "1px solid Green",
    borderImageSource: "linear-gradient(175.74deg, #FFFFFF 3.31%, #999999 96.39%)",
  },

});

const MenuClick = styled(Box)({
  background: "#01010199",
  padding: "4px 8px 4px 12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  height: "48px",
  boxSizing: "border-box",
  color: "#94A3B8",
  "& .MuiSvgIcon-root":{
    fontSize: "16px"
  }
});

const RecentSearch = styled(Box)({
  "& .rsTitle":{
    textTransform: "uppercase",
    paddingLeft: "16px",
    color: "#94A3B8",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    marginBottom: "16px"
  },
  "& .recentSearch": {
    maxHeight: '800px',
    overflow: 'scroll',
  }
})

const RenderScreens = styled(Box)({
  "& .rsTitle":{
    textTransform: "uppercase",
    paddingLeft: "16px",
    color: "#94A3B8",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    marginBottom: "16px"
  },
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100svh - 200px)',
})

const ScreenListItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #334155",
  backgroundColor: "#01010180",
  color: "#94A3B8",
  paddingLeft: "16px",
  gap: "5px",
  height: "50px",
  "& .screenName":{
    flex: "0.3",
    fontSize: "14px",
  },
  "& .region":{
    flex: "0.5",
    fontSize: "12px",
  },
  "& .icon":{
    flex: "0.1"
  },
})

const CustomDivider = styled(Divider)({
  backgroundColor: "#94A3B8",
  height: '2px',
})

const TableBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: "80%",

  "& .MuiPaper-root":{
    background: "transparent",
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  "& .MuiTableCell-root": {
    border: 'none'
  },
  "& .MuiTableCell-head": {
    color: "#94A3B8",
  },
  "& .MuiTableCell-body": {
    color: "#94A3B8",
  },
})

const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper":{
    background: '#010101CC',
    maxHeight: "calc(100% - 220px)",
    width: "100%",
  },
  "& .formControl":{
    padding: "12px 16px",
    "& .MuiTextField-root":{
      borderRadius: "24px"
    },
    "& .MuiOutlinedInput-root":{
      height: "40px",
      borderRadius: "24px",
      "&:hover .MuiOutlinedInput-notchedOutline":{
        borderColor: "#94A3B8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#94A3B8"
      },
      
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderWidth: "1px"
    },
  },
  "& .regionsList":{
    padding: 0,
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    marginTop: 0,
    marginBottom: 0,
    background: "#1B1B1BCC",
  },
  "& .regionsListItem":{
    height: "48px",
    padding: "12px 16px",
    background: "#1B1B1BCC",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFF",
    boxSizing: "border-box"
  },
  "& .regionsListItem:hover":{
    background: "#010101CC",
    color: "#FFF",
  }
})

const DetailedScreenBox = styled(Box)({
  background: '#000',
});
const Label = styled('label')({
  color: '#E2E8F0',
  padding: '0 0 4px',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  display: 'block',
});

const DetailedScreenDescription = styled(Box)({
  '& .list-items': {
    width: '100%',
    maxWidth: 360,
    textTransform: 'capitalize',
  },
  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: '20px',
    borderBottom: '1px solid #1E293B',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#FFF',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#FFF',
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2E8F0'
  },
  '& .MuiButtonBase-root': {
    color: '#FFF'
  },
  "& .MuiGrid-root": {
    padding: '16px'
  },
  "& input": {
    color: '#FFF'
  },
  "& .MuiInputBase-root": {
    borderRadius: '8px',
    border: '1px solid #FFF'
  },
  '& .white-icon': {
    color: '#FFF'
  },
});

const DetailedScreenTitle = styled(Box)({
  padding: '20px',
  '& .detailTitle': {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    textAlign: 'left',
  },
  '& .detailRegion': {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    paragraph: '12px',
  },
  textTransform: 'capitalize',
});

const DetailedScreenActions = styled(Box)({
  marginTop: '20px',
});

const GoBackToScreenButton = styled(Button)({
  padding: '16px 109px 16px 108px',
  gap: '8px',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  display: 'flex',
  bottom: '15px',
  margin: 'auto',
  fontWeight: 'bold',
  fontSize: '16px',
  width: '90%'
})

const CustomAlert = styled(Alert)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fdecea',
  color: '#f44336',
  borderLeft: '3px solid #f44336',
})

const RightAlignedTypography = styled(Typography)({
  textAlign: 'right',
  fontSize: '14px',
  cursor: 'pointer',
  color: ' #94A3B8',
  width: '100%'
});
// Customizable Area End
