import React, { ReactElement, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    IconButton,
    MenuItem,
    ListItemIcon,
    ListItemText,
    styled,
    CircularProgress,
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVert from '@material-ui/icons/MoreVert';
import { MdBlock, MdEdit, MdOutlineCheckCircle, MdOutlineDvr } from "react-icons/md";
import { CgMenuGridO } from "react-icons/cg";
import { BiCloudUpload } from "react-icons/bi";
import { LuMonitorOff } from "react-icons/lu";
import { get } from './table';
import { getStorageData } from "framework/src/Utilities";
import { FaRegCalendarCheck } from 'react-icons/fa';
import DevicesIcon from '@material-ui/icons/Devices';

const styles = {
    paper: {
        width: '100%',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    header: {
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#94A3B8',
        padding: '16px',
        borderBottom: "1px solid #334155",
        zIndex: 9999999999,
        position: 'sticky',
        top: 0,
    },
    headerCell: {
        // flex: 1,
        'text-align': 'left',
        fontWeight: 'bold' as const,
        fontSize: '14px',
    },
    headerCellWithTwo: {
        // flex: 1,
        'text-align': 'left',
        fontWeight: 'bold' as const,
        fontSize: '14px',
    },
    headerFilterCell: {
        'text-align': 'end',
        fontWeight: 'bold' as const,
        fontSize: '14px',
    },
    row: {
        display: 'flex',
        color: '#94A3B8',
        padding: '16px',
        background: "#01010180",
        borderBottom: "1px solid #334155",
        'align-items': 'center',
        height: '50px'
    },
    cell: {
        flex: 1,
        'text-align': 'left',
        fontSize: '12px',
        'text-transform': 'capitalize',
        fontWeight: 400,
        lineHeight: '18px'
    },
    fullCell: {
        flex: 1,
        'text-align': 'center',
        fontSize: '12px',
    },
    cellWithTwo: {
        flex: 1,
        'text-align': 'left',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px'
    },
    cellMenu: {
        textAlign: 'right',
    },
    statusAvailable: {
        backgroundColor: '#FEF3C7',
        color: '#D97706',
        padding: '2px 16px',
        borderRadius: '50px',
        display: 'inline-block',
    },
    statusUnAvailable: {
        backgroundColor: '#FEE2E2',
        color: '#F00',
        padding: '2px 8px',
        borderRadius: '50px',
        display: 'inline-block',
    },
    statusActive: {
        backgroundColor: '#D1FAE5',
        color: '#059669',
        padding: '2px 27px',
        borderRadius: '50px',
        display: 'inline-block',
    },
    container: {
        flexGrow: 1,
        overflowY: 'auto' as const,
    },
    iconColor: { color: "#94A3B8" },
    svg: {
        color: "#94A3B8",
        height: "25px",
        width: "25px",
    },
    tableDataRow: {
        background: "#01010180",
        borderBottom: "1px solid #334155",
    },
    tableHeader: {
        background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    },
    headerStyles: {
        padding: '10px 8px',
        background: 'transparent'
    },
};

interface RowData {
    screenName: string;
    rate: string;
    status: string;
}

interface State {
    rows: RowData[];
}


type headerKeyTypes = {
    title: string;
    key: string;
    dataKey: string;
}
type objectDataType = { [key: string]: any };

type TablePropTypes = {
    headerKeys: headerKeyTypes[];
    tableData: any[];
    showFilter: boolean;
    moreMenuList: { title: string, key: string, icon: ReactElement }[];
    isLoading?: boolean;
    handleMoreItem?: (key: string, data: objectDataType) => void;
    handleHeaderFilter?: (key: string) => void;
    navigation: any;
    handleBlock: () => void;
    handleSetCurrentRowData: (row: any) => void;
    handleSetIsAlphabatical: () => void;
    handleSetIsDateJoined: () => void;
    state: any
}

const headerFilterData = [
    {
        icon: <BiCloudUpload />,
        title: 'Alphabetical',
        key: 'alphabetical'
    },
    {
        icon: <MdBlock />,
        title: 'Date Joined',
        key: 'dateJoined'
    },
    {
        icon: <BiCloudUpload />,
        title: 'Active',
        key: 'active'
    }, {
        icon: <LuMonitorOff />,
        title: 'Inactive',
        key: 'inactive'
    }]

export default function UsersTable(props: TablePropTypes) {

    const { headerKeys, tableData, isLoading, handleMoreItem, navigation, handleBlock, handleSetCurrentRowData, handleSetIsAlphabatical, handleSetIsDateJoined, state } = props;
    const emptyRows = props.tableData.length;
    const [headerAnchorEl, setHeaderAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userData, setUserData] = React.useState<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currantRowData, setCurrantRowData] = React.useState<objectDataType>({});

    useEffect(() => {
        const getUserData = async () => {
            const userData = await getStorageData("userData", true);
            userData && setUserData(userData)
        }
        getUserData();

    }, [])

    const handleHeaderMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setHeaderAnchorEl(event.currentTarget);
    };

    const handleHeaderClose = () => {
        setHeaderAnchorEl(null);
    };

    const getChipColor = (value: string) => {
        if (value === 'inactive') return styles.statusUnAvailable;
        if (value === 'blocked') return styles.statusAvailable;
        if (value === 'active') return styles.statusActive;
        return { display: 'none' }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, rowData: objectDataType) => {
        setAnchorEl(event.currentTarget);
        setCurrantRowData(rowData);
        handleSetCurrentRowData(rowData)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrantRowData({});
    };

    const navigateToPage = (path: string, params: any) => {
        props.navigation && props.navigation.navigate(path, params)
    }

    const handleManagerMoreMenu = async (key: string) => {
        switch (key) {
            case 'block_user':
                handleBlock()
                handleClose()
                break;
            case 'view_ad_details':
                navigateToPage(`AdList`, {userId: currantRowData?.id})
                break;
            case 'view_user_details':
                navigateToPage(`ManagerUserDetail`, {userId: currantRowData?.id})
                break;

            default:
                handleClose()
                break;
        }
    }

    const handleMenuItemClick = (key: string) => {
        if (userData && userData.roleName === 'screen_manager') {
            handleManagerMoreMenu(key);
        } else {
            handleMoreItem && handleMoreItem(key, currantRowData);
            handleClose();
        }
    }

    const handleHeaderFilter = (key: string) => {
        if (key == "alphabetical") {
            handleSetIsAlphabatical()
        }
        if (key == "dateJoined") {
            handleSetIsDateJoined()
        }
        props.handleHeaderFilter && props.handleHeaderFilter(key);
        handleHeaderClose();
    }

    const getMoreMenuData = (status: any): any[] => {
        if (userData && userData.roleName === 'screen_manager') {
            return [
                {
                    title: "View User Details",
                    key: 'view_user_details',
                    icon: <FaRegCalendarCheck style={{height: "18px", width: "18px"}}/>,
                },
                {
                    title: "View Ad Details",
                    key: 'view_ad_details',
                    icon: <MdOutlineDvr style={{height: "18px", width: "18px"}}/>,
                },
                {
                    title: state.currentRowData.status === "blocked" ? "Unblock User" : "Block User",
                    key: "block_user",
                    icon: status === "blocked" ? (
                        <MdBlock style={{ height: "18px", width: "18px" }} />
                    ) : (
                        <MdBlock style={{ height: "18px", width: "18px" }} />
                    ),
                },
            ]
        } else {
            return [];
        }
    }

    return (
        <Paper style={{ ...styles.paper, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ position: 'relative', top: 0, zIndex: 1, display: "block" }}>
                <div style={{...styles.header, position: "relative", zIndex: 9999, top: 0, justifyContent:"space-between"}}>
                    <div style={{...styles.headerCellWithTwo, display: "flex", alignItems: "end"}}>User Name</div>
                    <div style={{...styles.headerCell, display: "flex", alignItems: "end"}}>Date Joined</div>
                    <div style={{...styles.headerCell, display: "flex", alignItems: "end"}}>Status</div>
                    <div style={{...styles.headerFilterCell, display: "flex", alignItems: "end", justifyContent: "flex-end"}}>
                        {props.showFilter && (
                            <>
                                <IconButton
                                    aria-haspopup="true"
                                    style={{ padding: 0 }}
                                    onClick={(event) => handleHeaderMenuClick(event)}
                                >
                                    <FilterListIcon style={styles.iconColor} />
                                </IconButton>
                                <StyledMenu
                                    id="customized-menu"
                                    anchorEl={headerAnchorEl}
                                    keepMounted
                                    style={{right: "25px"}}
                                    open={Boolean(headerAnchorEl)}
                                    onClose={handleHeaderClose}
                                >
                                    <StyledMenuItem
                                    >
                                        <ListItemText primary={"Sort by"} style={{ fontSize: "12px", fontWeight: 600, color: "#64748B" }} />
                                    </StyledMenuItem>
                                    {headerFilterData.map((item, index) => {
                                        return (
                                            <StyledMenuItem
                                                key={`${item.key}-${index}`}
                                                onClick={() => handleHeaderFilter(item.key)}
                                            >
                                                <ListItemText primary={item.title} style={{ fontSize: "12px" }}/>
                                            </StyledMenuItem>
                                        )
                                    })}
                                </StyledMenu>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div style={{...styles.container, display: "flex", flexDirection: "column"}}>
                {tableData.map((row: any, index: number) => (
                    <div style={styles.row} key={index}>
                        <div style={styles.cellWithTwo}>{get(row, 'name')?.length > 9 ? `${get(row, 'name').slice(0, 9)}...` : get(row, 'name')}</div>
                        <div style={styles.cell}>{get(row, 'date_joined')}</div>
                        <div style={styles.cell}>
                            <Typography style={{ ...getChipColor(get(row, 'status')), textAlign: 'center', textTransform: 'uppercase', fontSize: '12px', fontWeight: 700, width: '100px' }}>
                                {get(row, 'status')}
                            </Typography>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <IconButton
                                aria-haspopup="true"
                                style={{padding: 0, opacity: row.status != 'blocked' ? 1 : '0.6'}}
                                onClick={(event) => {
                                    if (row.status != 'blocked') {
                                        handleMenuClick(event, row)
                                    }
                                } 
                            }
                            >
                                <MoreVert style={styles.iconColor} />
                            </IconButton>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {getMoreMenuData(get(row, 'status')).map((menu, index: number) => (
                                    <StyledMenuItem key={`${menu.key}-${index}`} onClick={() => handleMenuItemClick(menu.key)}>
                                        <ListItemIcon style={{ minWidth: "35px" }}>
                                            {menu.icon}
                                        </ListItemIcon>
                                        <ListItemText style={{ fontSize: '14px' }} primary={menu.title} />
                                    </StyledMenuItem>
                                ))}
                            </StyledMenu>
                        </div>
                    </div>
                ))}
                {!isLoading && !emptyRows && (
                    <div style={{ ...styles.row, height: (53) * 1 }} >
                        <div style={styles.fullCell}>
                            No data
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div style={{ ...styles.row, height: (53) * 2, backgroundColor: 'transparent', border: 0 }}>
                        <div style={styles.fullCell}>
                            <CircularProgress style={styles.iconColor} />
                        </div>
                    </div>
                )}
            </div>
        </Paper>
    );

}

const StyledMenu = withStyles({
    paper: {
        background: "#000",
        color: "#94A3B8",
        borderRadius: "8px",
        boxShadow: 'none',
        zIndex: 1300,
    },
})(Menu)

const StyledMenuItem = styled(MenuItem)({
    color: '#94A3B8',
    minWidth: '180px',
    minHeight: "36px",
    height: "36px",
    '& svg': {
        color: '#94A3B8',
        height: '25px',
        width: '25px',
    },
});