import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  FormControlLabel,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import { ArrowBack, Close} from "@material-ui/icons";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {GreenCheckbox} from '../../email-account-login/src/EmailAccountLoginBlock.web'


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides:{
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "17.1%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiCheckbox:{
      root:{
        padding:'7px',
      }
    },
    MuiSvgIcon: {
      root: {
        width: "20px",
        height: "20px",
      },
    },
    MuiButton:{
      root:{
        textTransform:'none',
        fontSize:'0.8em',
        margin:'12 0 0',
      '&.cancelBtn':{
        color:'#FFFFFF',
        fontWeight:'700',
        margin:'32 0 0',
        height:'54px',
        background: '#000',
      },
      '&.agreeBtn':{
        background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
        borderRadius:'8px !important',
        height:'3.4em',
        fontSize:'1em',  
        fontWeight:700,
      },
      '&.Mui-disabled':{
        color:'#64748B',
        fontWeight:'700',
        backgroundColor:'#F1F5F9 !important',
      }
      }
    }
  }
});
// Customizable Area End

import TermsandconditionsController, {
  Props,
  configJSON,
} from "./TermsandconditionsController";


export default class Termsandconditions extends TermsandconditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.backgroundWrapper}>
      <ThemeProvider theme={theme}>
        
        <Grid container spacing={1} style={{margin:'0px 0px',borderBottom:'1px solid #E2E8F0',padding:'10px 0px',position: 'fixed', top: 0, width: '100%', zIndex: 10, background:'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
        <Grid item xs style={{padding:0,flexGrow:0}}>
          {this.state.token && <IconButton onClick={this.navigateBack}>
            <ArrowBack style={{height:'24px',width:'24px',color:'white'}}/>
          </IconButton>}
        </Grid>
          <Grid item sm xs={8} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100vw', marginLeft: this.state.token ? '0' : '45px',padding:this.state.token ? '0' : '12px 0px'}}>
            <Typography style={{textTransform:'uppercase',fontSize:'0.8em',fontWeight:'700',textAlign:'center'}}>Terms and conditions</Typography>
          </Grid>
          <Grid item xs container style={{justifyItems:'center',alignItems:'center',display:'flex'}}>
          {!this.state.token && <Button variant="text" onClick={this.navigateBack} style={{margin:'0px',padding:'0px',color:'white'}}><Close style={{height:'18px',fontSize:'1em',width:'18px'}} /></Button>}
          </Grid>
        </Grid>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography style={{color:'#FFFFFF',fontWeight:'700',fontSize:'1.2em',margin:'18 0'}}>{configJSON.labelTitleText}</Typography>
           
            {this.state.terms_and_condition_data.map(terms=>{
              return(
            <Typography component="div" style={{fontSize:'0.9em', color:'#FFFFFF',marginBottom:'8px',textAlign:'justify'}}>
              <div dangerouslySetInnerHTML={{ __html: terms.description}} />
            </Typography>)})}
            {!this.state.token &&
             <Grid container >
              
                <Grid item xs>
                  <FormControlLabel
                    data-test-id="formControl"
                    control={<GreenCheckbox style={{ background: "#fff",fontSize: '1em',  margin: '6px 10px 0 0', height: "0", width: "0", borderRadius: "0", color: this.state.isChecked ? '#059669' : 'transparent'}} data-test-id="checkbox" checked={this.state.isChecked} onChange={this.handleCheckBox} color="primary" />}
                    label={<span style={{ fontSize: '1em', paddingTop: '0px', fontWeight: 300, height: "0", width: "0" }}>I have read and agree to these Terms and Conditions</span>}
                    style={{ marginRight: '0px', alignItems: 'start',margin: '5px 0 7px 0'}}
                  />
                   {(this.state.isError) && (!this.state.isChecked)  && <span data-test-id="error-messages" style={{ fontSize: '0.85em', color: '#F87171', marginBottom: '24px',fontFamily: "Inter"}}>Please accept the Terms and Conditions to continue.</span>}
                </Grid>

              </Grid>}
            {!this.state.token && (
            <>
          <Button
            data-test-id="cancelBtn"
            fullWidth
            variant="text"
            className="cancelBtn"
            onClick={this.navigateBack}
          >
            Cancel
          </Button>
          <Button
            data-test-id="agreeBtn"
            fullWidth
            variant="text"
            className="agreeBtn"
            onClick={this.handleAgreeClickBtn}
          >
            Agree
          </Button>
          </>
          )}
          </Box>
        </Container>
      </ThemeProvider>
      </div>
   
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  backgroundWrapper:{
    background:'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    color:'#FFFFFF',
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    alignItems: "start",
    // padding: "10px 16px 30px",
    marginTop:'60px',
    padding:'10px'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  headerText:{
    textTransform:'uppercase'
  }
};
// Customizable Area End
