import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { get } from '../../../components/src/commonTable/table';

type StringOrNumber = string | number | null;
type RegionListDataType = {
  id: number; 
  name: string;
}
type objectDataType =  {[key:string]: string | {[key:string]: StringOrNumber}}
type screenListType =  {[key:string]: string}
interface ScreenFileAttributes {
  id: number;
  screen_id: number;
  status: string;
  file_name: string;
  upload_date: string;
  media_format: string;
  screen_name: string;
  amount: number;
  start_date: string;
  end_date: string;
  region: string;
  file: string;
}

interface ScreenFile {
  id: string;
  type: string;
  attributes: ScreenFileAttributes;
}
interface IStyleTypes {
  availableAlert: objectDataType;
  unavailableAlert: objectDataType;
  activeAlert: objectDataType;
}
type GeolocationPosition = {
  coords: {
    latitude: number;
    longitude: number;
    accuracy: number;
  };
};

type GeolocationPositionError = {
  code: number;
  message: string;
};
type SortDirection = 'asc' | 'desc';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedButton: string;
  addData: (object | number)[];
  index: number;
  header: string;
  before_title: string;
  after_title: string;
  before_description: string;
  after_description: string;
  in_the_bottom: string;
  active: boolean;
  selected: number;
  name: string;
  color: string;
  lists: (object | number)[];
  item: string;
  showParticularAdCallId: string;
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  regionsList: Array<RegionListDataType>;
  screenList: object[];
  recentScreenList: screenListType[];
  fileList: object[];
  regionSearch: string;
  screenSearch: string;
  currantTab: number;
  selectedRegion: string;
  isSearch: boolean;
  regionPopup: boolean;
  screenLoader: boolean;
  filesLoader: boolean;
  fileDetailId: StringOrNumber;
  fileDetailData: object;
  screenDetail: boolean;
  filterScreenBy: string;
  direction: SortDirection;
  openDescModal: boolean;
  displayAlert: boolean;
  displayAlertMessage: string;
  // Customizable Area End
}

interface SS {
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

export default class LocalListingAdsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // showParticularAdCallId: string;
  showParticularAdCallId: string = '';
  regionsListApiID: string = '';
  regionScreensApiID: string = '';
  recentScreensApiID: string = '';
  getFilesApiID: string = '';
  getFilesDetailsApiID: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.showParticularAdCallId = "";
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      selectedButton: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      index: 0,
      addData: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
      header: "",
      before_title: "",
      after_title: "",
      before_description: "",
      after_description: "",
      in_the_bottom: "",
      active: false,
      selected: 0,
      name: "bob",
      color: "white",
      item: " ",
      showParticularAdCallId: "",
      lists: [
        { id: 1, title: "S" },
        { id: 2, title: "M" },
        { id: 3, title: "L" },
        { id: 4, title: "XL" },
        { id: 5, title: "XXL" },
      ],

      // Customizable Area Start
      anchorEl: null,
      regionsList: [],
      screenList: [],
      recentScreenList: [],
      fileList: [],
      regionSearch: '',
      screenSearch: '',
      selectedRegion: '',
      isSearch: false,
      regionPopup: false,
      currantTab: localStorage.getItem("from") ? 1 : 0,
      screenLoader: false,
      filesLoader: false,
      fileDetailId: null,
      fileDetailData: {},
      screenDetail: false,
      filterScreenBy: 'all',
      direction: 'asc',
      openDescModal: false,
      displayAlert: false,
      displayAlertMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    if(localStorage.getItem("from")){
      localStorage.removeItem("from");
    }
    // Customizable Area End
  }
  async componentDidMount() {
    this.showParticularAdFunc();
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson){
        this.handleSuccess(responseJson, apiRequestCallId)
      }
    }
  }
  // Customizable Area End

  handlePress = (button: string) => {
    this.setState({ selectedButton: button });
  };

  // Customizable Area Start

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.currantTab !== prevState.currantTab) {
      this.getFileList();
    }
    setTimeout(() => {
      localStorage.removeItem('alert');
      this.setState({displayAlert: false});
    }, 1500)
  }

  getAlert = () => {
    const alertData = JSON.parse(localStorage.getItem('alert') || '{}');
    if(alertData.message) this.setState({displayAlert: true, displayAlertMessage: alertData.message});
  }

  options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  success = (pos: GeolocationPosition) => {
    const crd = pos.coords;
    this.getNearByScreens({
      "lat": crd.latitude,
      "lng": crd.longitude
    })
  };

  errors = (err: GeolocationPositionError) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };
  
  checkScreenManager = async() => {
    const userData = await getStorageData("userData", true);
    if(!userData) return false;
    return (userData && userData.roleName === 'screen_manager');
  }

  showParticularAdFunc = async() => {
    this.getRegionsList();
    this.getScreensList({});
    this.getFileList();
    this.getAlert();
    const userData = await getStorageData("userData", true);

    if (navigator.geolocation && window.location.pathname !== '/LocalListingFiles' && !(await this.checkScreenManager())) {

      history.pushState(null, document.title, location.href);
      addEventListener('popstate', function(event) {
        history.pushState(null, document.title, location.href);
      });

      navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(this.success, this.errors, this.options);
        }
      });
    }
  };
  apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
    bodyData?: {[key: string]: string | number}
  }) => {
    const { contentType, method, endPoint, bodyData } = data;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    bodyData && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  selectRegion = (value: string) => {
    this.setState({selectedRegion: value, filterScreenBy: ''})
    this.getScreensList({region: value})
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleSearchOfManager = async () => {
    if(await this.checkScreenManager()) this.navigateToOtherScreen('ManagerSearch')
  }

  handleSearch = () => {
    this.handleSearchOfManager();

    if(!this.state.isSearch) {
      this.getRecentScreensList();
    }
    this.setState({isSearch: !this.state.isSearch});
  }

  handleBack = () => {
    if(this.state.isSearch) this.handleSearch()
    else this.props.navigation?.navigate('HomeDashboard');
    }

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ 
      currantTab: value, 
      fileList: [] 
    });
  };

  a11yProps(index: number | string) {
    return {
      id: `scrollable-prevent-tab-${index}`,
      'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
  }
  
  handleFileDetailBack = () => {
    this.setState({fileDetailId: null, fileDetailData: {}})
  }

  handleFileMoreMenuClick = (keyData: string, data: {[key:string]: StringOrNumber}) => {
    data.id && this.getFileDetails(data.id);
    this.setState({
      fileDetailId: data?.id,
      fileDetailData: data || {}
    })
  }

  handleOpenDescModal = () => {
    this.setState({ openDescModal: true });
  }

  handleDescModalClose = () => {
    this.setState({ openDescModal: false });
  }

  handleRecentSearch = (screen: screenListType) => {
    this.setState({isSearch: false})
    this.getScreensList({screen: screen.screen_name, region: screen.region_name});
  }

  getScreenAPIUrl = ({screen, region}: {screen?: string, region?: string}) => {
    const endPoint = configJSON.regionScreensEndPoint;
    if(screen && region) return `${endPoint}screen=${screen}&region=${region}`;
    if(region && !screen) return `${endPoint}region=${region}`;
    if(screen && !region) return `${endPoint}screen=${screen}`;
    else return endPoint;
  }

  getRegionsList = async() => {
    this.regionsListApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.regionsListEndPoint
    })
  }

  getScreensList = async({screen, region}: {screen?: string, region?: string}) => {
    this.setState({screenLoader: true, screenList: []});
    this.regionScreensApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: this.getScreenAPIUrl({screen, region})
    })
  }

  getRecentScreensList = async() => {
    this.recentScreensApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.recentScreensEndPoint
    })
  }

  getFilesFilterQuery = (sortBy?: string) => {
    let query = configJSON.filePageFilterData[this.state.currantTab];
    if(sortBy === 'Alphabetical') query = query + `&file_name=true&order=${this.state.direction}`;
    if(sortBy === 'Upload Date') query = query + `&upload_date=true&order=${this.state.direction}`;
    return query;
  }

  getFileList = async (sortBy?: string) => {
    this.setState({filesLoader: true, fileList: []});
    this.getFilesApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.filesEndPoint + this.getFilesFilterQuery(sortBy),
    });
  };

  getFileDetails = async (dataId: string | number) => {
    this.setState({filesLoader: true});
    this.getFilesDetailsApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.detailFileEndPoint + dataId,
    });
  };

  getNearByScreens = async(bodyData: {[key: string]: number | string}) => {
    this.setState({screenLoader: true, screenList: []});
    this.regionScreensApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.nearByScreensEndPoint,
      bodyData,
    })
    
  }

  handleNavigationChange = () => {
    this.props.navigation.navigate('LocalListingAds')
  }

  navigateToOtherScreen(screenName?:string,params?:any){
    if(screenName){
      const linkMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
      
      if(params){
        if(screenName === "PaymentScreen"){
          if(params === "activeScreen"){
            localStorage.setItem('selectedActiveScreen', JSON.stringify(this.state.fileDetailData));
          } else {
            localStorage.setItem('selectedScreen', JSON.stringify(params));
          }
        }
      }
      linkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      linkMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      this.send(linkMsg);
    }
    else{
      this.props.navigation.goBack();
    }
  }

  getChipColor = (styles: IStyleTypes) => {
    const status = this.getFieldValue('status');
    if(status === 'unavailable') return styles.unavailableAlert;
    if(status === 'available') return styles.availableAlert;
    if(status === 'active') return styles.activeAlert;
    return {display: 'none'}
  };

  handleSuccess = (
    responseJson:
      {regions:Array<RegionListDataType>} & {file: {data: {}}} & {recent_search: screenListType[]} &
      {[key:string]: Array<{[key:string]: {[key:string]: StringOrNumber}}>} & {data: ScreenFile[]}, 
    apiRequestCallId:string
  ) => {
    switch(apiRequestCallId) {
      case this.regionsListApiID:
        this.setState({
          regionsList: responseJson.regions || []
        });
        break;
      case this.regionScreensApiID:{
          let fetchedData = responseJson.data || []
          if(this.state.filterScreenBy === 'active') {
            fetchedData = fetchedData.filter((data) => data.attributes.status === 'active');
          }
          else if(this.state.filterScreenBy === 'available') {
            fetchedData = fetchedData.filter((data) => data.attributes.status === 'available');
          }
          else if(this.state.filterScreenBy === 'unavailable') {
            fetchedData = fetchedData.filter((data) => data.attributes.status === 'unavailable');
          }
          this.setState({
            screenList: fetchedData,
            screenLoader: false,
          });
        }
        break;
      case this.recentScreensApiID:
        this.setState({
          recentScreenList: responseJson.recent_search || []
        });
        break;
      case this.getFilesApiID: {
          this.setState({
            fileList: responseJson.data || [],
            filesLoader: false
          });
        }
        break;
      case this.getFilesDetailsApiID:
        const { data } = responseJson.file;
        this.setState({
          fileDetailData: data || {},
          filesLoader: false
        });
        break;
      default:
        break;
    }
    
  }
    
  handleRegionSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      regionSearch: event.target.value
    })
  }

  handleSearchScreen = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({screenSearch: event.target.value, filterScreenBy: ''})
  }

  handleSearchScreenList = () => {
    this.getScreensList({screen: this.state.screenSearch, region: this.state.selectedRegion})
    this.setState({isSearch: false});
  }

  handleHeaderFilter= (dataKey: string) => {
    this.setState({filterScreenBy: dataKey})
    if(dataKey === 'all') this.setState({screenSearch: '', selectedRegion: ''});
    this.getScreensList({});
  }

  handleScreenDetailDrawerClose = () => {
    if(this.state.selectedButton === 'check_availability' && this.getFieldValue('status') !== 'unavailable'){
      this.navigateToOtherScreen('PaymentScreen', "activeScreen" )
    } else {
      this.setState({ screenDetail: false })
    }
  }

  handleScreenMoreMenuClick = (keyData: string, data: {[key: string]: StringOrNumber}) => {
    if(keyData === 'details' || keyData === 'check_availability') {
      this.setState({
        screenDetail: true,
        selectedButton: keyData,
        fileDetailId: data?.id,
        fileDetailData: data || {}
      })
    }
    if(keyData === 'upload'){
      this.navigateToOtherScreen('PaymentScreen', data)
    }
  }

  getFieldValue = (keyValue: string): string => {
    return get(this.state.fileDetailData, `attributes.${keyValue}`)
  }
  getDateValue = (keyValue: string): string => {
    return get(this.state.fileDetailData, `attributes.files.${keyValue}`)
  }

  handleFileFilterData = (index: number) => {
    this.setState(prevState => ({
      filterScreenBy: configJSON.filesFilterDataList[index],
      direction: prevState.direction === 'asc' ? 'desc' : 'asc'
    }));    
    this.getFileList(configJSON.filesFilterDataList[index]);
    this.handleMenuClose();
  }

  clearSearchAll = () => {
    this.setState({ screenSearch: '' });
    this.setState({selectedRegion:''});
  }
  clearSearch = () => {
    this.setState({ screenSearch: '' });
  };

  // Customizable Area End
}
