import React from 'react';
import LocalListingAdsController, { Props } from './LocalListingAdsController';
import {
  FaArrowLeft,
  FaRegHeart,
  FaMinusCircle,
  FaPlusCircle,
} from 'react-icons/fa';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { girl } from './assets';
import { IconContext } from 'react-icons/lib';
// Customizable Area Start
import {
  withStyles,
} from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  styled,
  Tabs,
  Tab,
  Divider,
  IconButton,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Button,
  TableContainer,
  Container,
  CircularProgress
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import {FilterList as FilterListIcon, ArrowBack} from '@material-ui/icons';
import { MdOutlineDvr } from "react-icons/md";
import FileTable from '../../../components/src/commonTable/FileList';
import {get} from '../../../components/src/commonTable/table';
const configJSON = require('./config');
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { FileRejectionScreen } from '../../../components/src/FileRejection';
import { secondsToTime } from '../../../components/src/addNewScreen';


const moreMenu = [
  {
    title: "Details",
    key: 'details',
    icon: <MdOutlineDvr />
  }
]


// Customizable Area End

export default class LocalListingAds extends LocalListingAdsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderHeader = () => {
    return (
      <StyledHeader>
        <Box />
        <Typography className="pageTitle">
          {configJSON.fileHeader}
        </Typography>
        <IconButton data-test-id="searchIcon" onClick={this.handleMenuOpen}>
          <FilterListIcon />
        </IconButton>
        <StyledMenu
          data-test-id="customized-menu"
          id="customized-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          style={{minWidth: '180px'}}
        >
          <Typography variant='subtitle2' style={{fontSize: '12px', paddingLeft: '16px', fontWeight: 700}}> Sort by </Typography>
          {configJSON.filesFilterDataList.map((itemKey: string, index: number) => (
            <StyledMenuItem
              data-test-id={itemKey}
              key={`${itemKey}-${index}`}
              onClick={() => this.handleFileFilterData(index)}
            > {itemKey} </StyledMenuItem>
          ))}
        </StyledMenu>
      </StyledHeader>
    );
  };

  detailScreenHeader = () => {
    return (
      <DetailScreenHeader>
        <ArrowBack data-test-id="go-back" onClick={this.handleFileDetailBack} style={{width:'24px',height:'24px'}}/>
      </DetailScreenHeader>
    );
  }

  renderTopActions = () => {
    return (
      <>
        <TopActions>
          <Tabs
            data-test-id="top_actions"
            value={this.state.currantTab}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="off"
            indicatorColor="primary"
            textColor="primary"
          >
            { configJSON.filePageTitleEnum.map((dataKey: string, index: number) => (
              <Tab key={`${dataKey}-${index}`} label={dataKey} {...this.a11yProps(index)} />
            ))}
          </Tabs>
        </TopActions>
      </>
    );
  };

  renderScreens = () => {
    return (
      <>
        <ScreenBox id="file-list-screen">
          <Typography className="rsTitle">
            {this.state.fileList.length} {configJSON.regionScreensResultTxt}
          </Typography>
          <CustomDivider variant="middle" style={{margin:"0px 24px", height: '1px'}}/>
          {(configJSON.filePageFilterData[this.state.currantTab] === 'rejected' && !this.state.fileList.length) ? 
            ( this.renderNullFilesData() ) : (
              <TableBox>
                <UpdatedTableContainer>
                  <FileTable
                    data-test-id="table"
                    headerKeys={configJSON.filesListHeaderKeys}
                    tableData={this.state.fileList}
                    showFilter={false}
                    moreMenuList={moreMenu}
                    isLoading={this.state.filesLoader}
                    handleMoreItem={this.handleFileMoreMenuClick}
                  />
                </UpdatedTableContainer>
              </TableBox>
          )}
        </ScreenBox>
      </>
    );
  };

  renderNullFilesData = () => {
    return (
      !this.state.filesLoader ? (<Container style={webStyle.mainWrapper}>
        <FileRejectionScreen 
            webStyle={webStyle}
            navigation={this.props.navigation}
            state={this.state}
            handleDescModalClose={this.handleDescModalClose}
            handleDescModalOpen={this.handleOpenDescModal}
        /> 
      </Container>) : (
        <div style={{ ...webStyle.row, height: (53) * 2, backgroundColor: 'transparent', border: 0 }}>
          <div style={webStyle.fullCell}>
            <CircularProgress style={webStyle.iconColor} />
          </div>
        </div>
      )
    )
  }

  renderDetailScreen = () => (
    <DetailedScreenBox>
      <Typography className="detailTitle" gutterBottom>{configJSON.filePageTitleEnum[this.state.currantTab]}</Typography>
      <CustomDivider style={{backgroundColor:'#64748B', height: '1px'}}/>
      <List className='list-items'>
        <ListItem>
          <ListItemText 
            primary={"Region"} 
            secondary={get(this.state.fileDetailData, "attributes.region")} 
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Screen Name"}
            key="screen_name"
            secondary={get(this.state.fileDetailData, "attributes.screen_name")} 
          />
        </ListItem>
        <ListItem>
          <ListItemText
            key="file_name"
            secondary={get(this.state.fileDetailData, "attributes.file_name")} 
            primary={"File Name"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Media Formate"}
            secondary={get(this.state.fileDetailData, "attributes.media_format")} 
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Duration"}
            secondary={secondsToTime(get(this.state.fileDetailData, "attributes.duration"))} 
          />
        </ListItem>
        <ListItem>
          <ListItemText
            secondary={get(this.state.fileDetailData, "attributes.start_date") || '-'} 
            primary={"Start Date"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id="end_date"
            primary={"End Date"}
            secondary={get(this.state.fileDetailData, "attributes.end_date") || '-'} 
          />
        </ListItem>
        <ListItem style={{borderBottom: '0'}}>
          <ListItemText
            primary={"Amount"}
            id="amount"
            secondary={`$${get(this.state.fileDetailData, "attributes.total_amount")}`} 
          />
        </ListItem>
      </List>
      <ButtonWrapper>
      <GoBackToScreenButton onClick={this.handleNavigationChange} data-test-id="go-to-screens">
        {configJSON.goToScreens}
      </GoBackToScreenButton>
      </ButtonWrapper>        
    </DetailedScreenBox>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <HamburgerMenu 
        navigation={this.props.navigation} 
        fromPage="files" 
        hideBottom={!!this.state.fileDetailId}
      >
          <Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
            <Toolbar style={{backgroundColor:'transparent', padding: 0}}>
              {!this.state.fileDetailId && this.renderHeader()}
              {this.state.fileDetailId && this.detailScreenHeader()}
            </Toolbar>
            <MainContent>
              {!this.state.fileDetailId && this.renderTopActions()}
              {!this.state.fileDetailId && this.renderScreens()}
              {this.state.fileDetailId && this.renderDetailScreen()}
            </MainContent>
          </Box>
      </HamburgerMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  containerStyle: {
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    height:'92%'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    'text-align': 'center',
    fontFamily: "Inter",
    'flex-direction': "column",
    paddingTop: '50px',
    paddingBottom: "15%",
    '@media (max-height:700px)': {
      marginBottom:'20%'
    },
    
  },
  mainWrapper: {
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    paddingTop: '20px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  typoDescription: {
    color: '#CBD5E1',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  typoGuide: {
    paddingTop: '8px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#E2E8F0',
    fontWeight: 600,
  },
  uploadBox: {
    border: '2px dashed #b3b3b3',
    borderRadius: '16px',
    width: '100%',
    margin: '24px',
    'text-align': 'center',
    gap: '16px',
  },
  uploadIcon: {
    fontSize: '48px',
    color: '#b3b3b3',
  },
  uploadText: {
    fontSize: '16px',
    color: '#f1f5f9',
    fontWeight: 700,
  },
  uploadDesc: {
    fontSize: '14px',
    color: '#E2E8F0',
    fontWeight: 400,
    cursor: 'pointer',
  },
  
  button: {
    marginTop: '16px',
    fontSize: '16px',
    color: '#fff',
    fontWeight: 700,
    "& .MuiButton-label": {
      'align-items': 'center',
    }
  },
  link: {
    color: '#E2E8F0',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  row: {
    display: 'flex',
    color: '#94A3B8',
    padding: '10px',
    background: "#01010180",
		borderBottom: "1px solid #334155",
    'align-items': 'center',
    gap: '20px'
  },
  fullCell: {
    flex: 1,
    'text-align': 'center',
    fontSize: '12px',
  },
  iconColor: { color: "#94A3B8" },
};

const StyledMenu = withStyles({
  paper: {
    background: '#000',
    color: '#94A3B8',
    borderRadius: '8px',
    padding: '0px',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledHeader = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  alignItems: 'centner',
  padding: '20px 16px 16px 20px',
  borderBottom: '1px solid #64748B',
  color: '#FFF',
  '& .pageTitle': {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  '& .MuiIconButton-root': {
    padding: '0px',
  },
  '& .MuiSvgIcon-root': {
    color: '#FFF',
  },
});

const DetailScreenHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'centner',
  padding: '20px 16px 16px 20px',
  color: '#FFF',
});

const MainContent = styled(Box)({
  position: 'relative',
  maxHeight: '100vh',
});

const TopActions = styled(Box)({
  padding: '16px 12px 20px 12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  '& .MuiTabs-root': {
    borderRadius: '50px',
    background: '#4E4E4E',
    padding: '4px', 
  },
  '& .MuiButtonBase-root': {
    font: 'Inter',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#F8FAFC',
    textTransform: 'none',
    padding:'8px 24px',
  },
  '& .Mui-selected': {
    borderRadius: '50px',
    padding: '8px 24px',
    gap: '41px',
    background: '#3C3C3C',
    color: '#F8FAFC'
  },
  '& .MuiTabs-indicator': {
    background: 'transparent'
  },
});

const ScreenBox = styled(Box)({
  '& .rsTitle': {
    textTransform: 'uppercase',
    paddingLeft: '16px',
    color: '#94A3B8',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
    marginBottom: '8px',
  },
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100svh - 200px)',
});

const DetailedScreenBox = styled(Box)({
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  height: 'calc(100svh - 61px)',
  overflow: 'auto',
  '& .detailTitle': {
    color: '#fff',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.15px',
    textAlign: 'left',
    padding: '8px 20px'
  },
  '& .list-items': {
    width: '100%',
    maxWidth: 360,
    
  },
  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: '20px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
    marginBottom: '4px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 0',
  width: '100%',
});

const GoBackToScreenButton = styled(Button)({
  padding: '16px 0px 16px 0px',
  gap: '8px',
  color:'#000000',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  fontWeight: 'bold',
  textTransform:'full-size-kana',
  fontSize: '16px',
  width: '90%',
  margin:"auto",
})

const CustomDivider = styled(Divider)({
  backgroundColor: '#94A3B8',
  height: '2px',
});

const TableBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '80%',
  '& .MuiPaper-root': {
    background: 'transparent',
  },
  '& .MuiTableCell-root': {
    border: 'none',
  },
  '& .MuiTableCell-head': {
    color: '#94A3B8',
  },
  '& .MuiTableCell-body': {
    color: '#94A3B8',
  },
  '& .table-data-row': {
    background: '#01010180',
    borderBottom: '1px solid #334155',
  },
});
const UpdatedTableContainer = styled(TableContainer)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom:"12px",
  '@media (min-device-width: 390px) and (max-device-width: 844px) and (orientation: portrait)': {
    height: '670px', 
  },
  '@media (max-device-height: 892px) and (orientation: portrait)': {
    height: '640px',
  },
  '@media (min-device-width: 360px) and (max-device-width: 375px) and (orientation: portrait)': {
    height: '550px',
  },
  '@media (min-device-width: 360px) and (max-device-width: 375px) and (max-device-height: 668px) and (orientation: portrait)': {
    height: '430px',
  },
})

const StyledMenuItem = styled(MenuItem)({
  color: '#64748B',
  minWidth: '120px',
  minHeight: '30px',
  fontSize: '14px',
  fontWeight: 400,
});

// Customizable Area End
