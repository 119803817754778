import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  InputLabel,
  TextField,
  Grid,
  FormControlLabel,
  Link,
  OutlinedInput,
  CheckboxProps,
  Collapse,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { app_logo, background, imgviewa, imgviewb } from "./assets";
import { CheckCircle } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
export const GreenCheckbox = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: 'rgb(52, 211, 153)',
    },
    borderRadius: '50px'
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      color: 'green'
    },
    h5: {
      fontWeight: 600,
      color: "red"
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body2: {
      fontSize: '0.7em',
    },
  },
  overrides: {

    MuiSvgIcon: {
      root: {
        width: "18px",
        height: "18px",
        '&.checkIcon': {
          width: "24px",
          height: "24px",
        }
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '8px !important'
      },
      input: {
        height: '2em',
        "&::placeholder": {
          fontSize: "14px",
        },
        "&:focus": {
          borderRadius: '2px'
        },
      },

    },
    MuiOutlinedInput: {
      root: {
          outline: "1px solid #CBD5E1",
      },
      input: {
        padding: '10.5px'
      }
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "17.1%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiCheckbox: {
      root: {
        padding: '5px 5px 5px 9px',
      },

    },
    MuiButton: {
      root: {
        textTransform: 'none',
        height: '3.6em'
      }
    },
    MuiIconButton:{
      root:{
        marginRight:'-6px !important',
        color: 'rgb(148, 163, 184)',
      }
    },
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          display: 'flex',
          left: '4%',
          justifyContent: 'center',
          bottom: '7%',
          width: '92%'
        },

        '&.alertCollapse > div> div > div': {
          backgroundColor: 'white !important',
          boxShadow: '0px 4px 7px grey',
          padding: '8px 16px',

          fontSize: '1em',
          borderRadius: '8px',
          color: '#0F172A'
        }
      }
    },

  },
});
const webStyle = {
  logos: {
    filter: 'invert(1)',
  },
  alertBox: {
    padding: '8px 12px',
    backgroundColor: '#FEE2E2',
    borderLeft: '4px solid red',
    color: 'red',
    fontSize: '0.9em',
    borderRadius: '4px',
    margin: "40px 0 12px 0",
    width: '90%'
  },
  inputLabel: {
    fontSize: '1em',
    fontWeight: '700',
    margin: '8 0',
    color:'#E2E8F0',
  },
  inputValue: {
    marginBottom: theme.spacing(3),
    color: 'rgba(226, 232, 240,1)',
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    fontSize: '1em',
    fontWeight: 400,
  }
}
// Customizable Area End
export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <div style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',position:'fixed',width:'100vw',backgroundRepeat: 'no-repeat',backgroundSize: '100% 100%', height: "100vh", overflowY: 'auto', overflowX: 'hidden'}}>
        <Container maxWidth={"sm"} style={{ height: '100%', alignContent: 'center'}}>
        <img src={imgviewa} alt="imgviewa" style={{position:'absolute',top:"0px",right:"-25px"}}></img>
          <img src={imgviewb} alt="imgviewb" style={{position:'absolute',bottom:0,left:0}}></img>
          <Box sx={{
            display: "flex",
            fontFamily: "Inter",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'center',
            padding: "30px 0",
          }}>
            <img src={app_logo} alt="app_logo"style={webStyle.logos} />
            {(this.state.isFormSubmit && this.state.errorMsg) &&
              <Box sx={webStyle.alertBox} className="alert-box">
                {this.state.errorMsg != "" ? this.state.errorMsg : null}
              </Box>
            }
              <form autoComplete="off" autoSave="off" style={{
                width: '100%',
                marginTop: theme.spacing(4),
              }}>
                <InputLabel style={{ ...webStyle.inputLabel }}>
                  Email
                </InputLabel>
                <OutlinedInput
                  data-test-id="txtInputEmail"
                  required
                  fullWidth
                  id="email"
                  autoComplete="off"
                  autoCorrect="off"
                  autoSave="off"
                  placeholder="Your email"
                  name="email_abc123"
                  value={this.state.email}
                  onChange={(event) => this.setEmail(event.target.value)}
                  style={{ ...webStyle.inputValue }}
                />
                <InputLabel style={{ ...webStyle.inputLabel }}>
                  Password
                </InputLabel>
                <OutlinedInput
                  data-test-id="txtInputPassword"
                  required
                  fullWidth
                  name="password_abc123"
                  type={!this.state.enablePasswordField ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  autoSave="off"
                  placeholder="Your password"
                  style={{ ...webStyle.inputValue, marginBottom: '0px' }}
                  value={this.state.password}
                  onChange={(event) => this.setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        data-test-id="password-toggle"
                        edge="end"
                      >
                        {!this.state.enablePasswordField ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }

                />
              <Grid container alignContent="space-between" alignItems="center">
                <Grid item xs>
                  <FormControlLabel
                    data-test-id='formControlRememberMe'
                    control={<GreenCheckbox style={{ fontSize: '1.2em'}} checked={this.state.checkedRememberMe} value="remember" data-test-id="btnRememberMe" onChange={(event) => this.setRememberMe(event.target.checked)} />}
                    label={<span style={{ fontSize: '0.9em', fontWeight: 400, color: 'rgb(148, 163, 184)',padding:'9px'}}>Remember me</span>}
                  />
                </Grid>
                <Grid item>
                  <Button data-test-id="forgotPassLink" onClick={() => this.navigateToLink('ForgotPassword')} variant="text" style={{ color: '#DC2626', fontSize: "0.9em", margin: "-5px 0px", lineHeight: '26px', fontWeight: 700 }}>
                    Forgot password?
                  </Button>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                data-test-id="loginBtn"
                variant="contained"
                onClick={this.onLogin}
                style={{
                  margin: theme.spacing(3, 0, 5),
                  background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
                  color: "#1E293B",
                  fontWeight:'700',
                }}
              >
                Log in
              </Button>
              <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'baseline' }}>
                <span style={{ fontSize: '1em', color: '#E2E8F0' }}>Don’t have an account?</span>
                <Button data-test-id="linkToNavigate" onClick={this.naviagteToSignUp} variant="text" style={{ color: '#F8FAFC', fontSize: '0.9em', fontWeight: 700, marginLeft: 3 }}>
                  Sign up
                </Button>
              </div>
            </form>
            <Collapse className="alertCollapse" in={this.state.accountDeleted} >
              <Alert icon={<CheckCircle className="checkIcon" style={{ color: '#34D399' }} />} severity={'success'}  >
                {'Your account has been deleted successfully'}
              </Alert>
            </Collapse>
          </Box>
        </Container>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
