Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LocalListingAds";
exports.labelBodyText = "LocalListingAds Body";
exports.regionsListEndPoint = "bx_block_custom_form/screens/screen_regions";
exports.regionScreensEndPoint = "/bx_block_custom_form/screens/filter_screens?";
exports.recentScreensEndPoint = "bx_block_content_moderation/moderation/recent_search";
exports.detailFileEndPoint = "/bx_block_custom_form/bookings/file_details?file_id="
exports.filesEndPoint = "bx_block_custom_form/screens/screen_files?status=";
exports.nearByScreensEndPoint = "/bx_block_custom_form/screens/nearby_screens";

exports.btnExampleTitle = "CLICK ME";
exports.screenHeader = "SCREENS";
exports.fileHeader = "FILES";
exports.headerSearchTxt = "Search";
exports.searchScreensPlaceholderTxt = "Search for screens";
exports.selectRegionTxt = "Select region";
exports.recentSearchTxt = "Recent searches";
exports.regionScreensResultTxt = "Results";
exports.screenListHeaderKeys = [
  {
    title: "Screen Name",
    key: "screen_name",
    dataKey: "attributes.screen_name"
  },
  {
    title: "Rate",
    key: "rate",
    dataKey: "attributes.price"
  },
  {
    title: "Status",
    key: "status",
    dataKey: "attributes.status"
  },
  {
    title: "",
    key: "action",
    dataKey: ""
  },
];
exports.filesListHeaderKeys = [
  {
    title: "File Name",
    key: "file_name",
    dataKey: "attributes.file_name"
  },
  {
    title: "Upload Date",
    key: "upload_date",
    dataKey: "attributes.upload_date"
  },
  {
    title: "Screen Name",
    key: "screen_name",
    dataKey: "attributes.screen_name"
  },
  {
    title: "",
    key: "action",
    dataKey: ""
  },
];
exports.filePageTitleEnum = ["Under Revision", "Rejections", "Published", "Approved"]
exports.filePageFilterData = ["under_revision", "rejected", "published", "approved"]
exports.filesFilterDataList = ["Alphabetical", "Upload Date"];
exports.ScreenStatusData = ["active", "available", "unavailable"]
exports.ScreenFileTypeData = ["Video", "Image"]
exports.goToScreens = "Go to Screens";
exports.back = "Back";
exports.upload = "Upload";
exports.pause = "Pause";
exports.cancel = "Cancel";
// Customizable Area End